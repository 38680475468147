import React, { useContext, useState } from "react"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { AuthContext } from "../../context/Auth/AuthContext";
import { BottomNavigation, BottomNavigationAction, Fab, Snackbar } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MessageBox from "./newMessageBox";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Automations = () => {
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const { user } = useContext(AuthContext);
    var userQueueIds = [];

    if (user.queues && user.queues.length > 0) {
        userQueueIds = user.queues.map(q => q.id);
    }

    const handleCloseSuccess = () => {
        setSuccessOpen(false);
    };

    return (
        <div>
            <Container maxWidth="lg" className={classes.container}>
                <Snackbar
                    open={successOpen}
                    autoHideDuration={4000}
                    onClose={handleCloseSuccess}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={handleCloseSuccess} severity="success">
                        El mensaje se ha programado exitosamente
                    </Alert>
                </Snackbar>
                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    showLabels
                    className={classes.root}
                >
                    <BottomNavigationAction label="Programados" icon={<ScheduleIcon />} />
                    <BottomNavigationAction label="Enviados" icon={<DoneIcon />} />
                </BottomNavigation>
                {value === 0 && <div></div>}
                {value === 1 && <div>Aqui puedes ver los mensajes ya enviados</div>}
                {open && (
                    <MessageBox
                        open={open}
                        setOpen={setOpen}
                        successOpen={successOpen}
                        setSuccessOpen={setSuccessOpen}
                    />
                )}
                <Fab color="primary" className={classes.fab} onClick={() => setOpen(true)}>
                    <AddIcon />
                </Fab>
            </Container>
        </div>
    );
}

export default Automations;
