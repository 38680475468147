import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    TextField,
    Grid,
    BottomNavigation,
    BottomNavigationAction,
    Snackbar,
} from "@material-ui/core";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import PeopleIcon from "@material-ui/icons/People";
import ContactSelector from './ContactSelector';
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        minHeight: "550px",
        maxHeight: "700px",
        minWidth: "650px",
        maxWidth: "800px",
    },
    textField: {
        width: "100%",
        marginTop: theme.spacing(2),
    },
    dateTimeContainer: {
        marginTop: theme.spacing(2),
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MessageBox = (props) => {
    const classes = useStyles();
    const { open, setOpen, successOpen, setSuccessOpen } = props;
    const [value, setValue] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(new Date());
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [messageContent, setMessageContent] = useState('');
    const [isSwitchOn, setSwitchOn] = useState(false);
    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleDateChange = (event) => {
        const date = new Date(event.target.value);
        setSelectedDate(date);
    };

    const handleTimeChange = (event) => {
        const time = event.target.value;
        const [hours, minutes] = time.split(":");
        const newDate = new Date(selectedDate);
        newDate.setHours(parseInt(hours, 10));
        newDate.setMinutes(parseInt(minutes, 10));
        setSelectedTime(newDate);
    };

    function handleClose() {
        setOpen(false);
    }

    const handleSwitchChange = (checked) => {
        setSwitchOn(checked);
    };

    const handleSave = () => {
        if (!messageContent) {
            setError('El contenido del mensaje no puede estar vacío.');
            setSnackbarOpen(true);
            return;
        }

        if (!isSwitchOn && selectedContacts.length === 0) {
            setError('Debe seleccionar al menos un destinatario.');
            setSnackbarOpen(true);
            return;
        }

        // Handle saving the message here
        // If saving is successful:
        setSnackbarOpen(false); // Close error alert if open
        setSuccessOpen(true);
        setOpen(false);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ className: classes.dialogPaper }}
            >
                <DialogTitle>Programar nuevo mensaje</DialogTitle>
                <DialogContent>
                    <BottomNavigation
                        value={value}
                        onChange={(event, newValue) => setValue(newValue)}
                        showLabels
                    >
                        <BottomNavigationAction
                            label="Contenido"
                            icon={<ChatBubbleOutlineIcon />}
                        />
                        <BottomNavigationAction
                            label="Destinatarios"
                            icon={<PeopleIcon />}
                        />
                    </BottomNavigation>
                    {value === 0 && (
                        <>
                            <TextField
                                label="Contenido del mensaje"
                                multiline
                                rows={3}
                                rowsMax={8}
                                variant="outlined"
                                value={messageContent}
                                onChange={(e) => setMessageContent(e.target.value)}
                                className={classes.textField}
                                inputProps={{ style: { overflow: "auto" } }}
                            />
                            <div className={classes.dateTimeContainer}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="date"
                                            label="Fecha de envío"
                                            InputLabelProps={{ shrink: true }}
                                            value={selectedDate.toISOString().split("T")[0]}
                                            onChange={handleDateChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="time"
                                            label="Hora de envío"
                                            InputLabelProps={{ shrink: true }}
                                            value={selectedTime.toTimeString().substring(0, 5)}
                                            onChange={handleTimeChange}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    )}
                    {value === 1 && (
                        <ContactSelector
                            selectedContacts={selectedContacts}
                            setSelectedContacts={setSelectedContacts}
                            isSwitchOn={isSwitchOn}
                            onSwitchChange={handleSwitchChange}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        disabled={!messageContent || (!isSwitchOn && selectedContacts.length === 0)}>
                        Programar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </>
    );
};

export default MessageBox;
