import React, { useState, useEffect } from 'react';
import useAllTables from '../../hooks/useTables';
import './styles.css';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

const TicketsCerradas = () => {
    const { tickets, loading } = useAllTables();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [graphData, setGraphData] = useState([]);
    const [ticketCount, setTicketCount] = useState(0)
    useEffect(() => {
        if (loading || !startDate || !endDate) return;

        const start = new Date(startDate);
        const end = new Date(endDate);

        // Filter tickets based on status and date range
        const filteredTickets = tickets.filter(ticket => {
            let ticketCreatedAt = new Date(ticket.createdAt);
            return ticket.status === 'closed' && ticketCreatedAt >= start && ticketCreatedAt <= end;
        });
        setTicketCount(filteredTickets.length);

        // Group data by day
        const dataByDay = {};
        filteredTickets.forEach(ticket => {
            const date = new Date(ticket.createdAt).toLocaleDateString();
            if (!dataByDay[date]) {
                dataByDay[date] = 0;
            }
            dataByDay[date]++;
        });

        // Convert data to array format for recharts
        const data = Object.keys(dataByDay).map(date => ({
            date,
            'Tickets Cerradas': dataByDay[date]
        }));

        setGraphData(data);
    }, [tickets, loading, startDate, endDate]);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div style={{ padding: '30px' }}>
            <h2 style={{ color: '#2576d2' }} className="MuiTypography-root MuiTypography-h6 MuiTypography-colorPrimary MuiTypography-gutterBottom">
                Estadísticas de Tickets Cerradas
            </h2>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <div className='inputContainer'>
                    <label htmlFor="startDate" style={{ marginRight: '10px' }}>Inicio:</label>
                    <input
                        type="date"
                        id="startDate"
                        value={startDate}
                        onChange={handleStartDateChange}
                        style={{
                            padding: '5px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            fontFamily: 'Arial, sans-serif',
                            color: '#333',
                            fontSize: '14px',
                        }}
                    />
                </div>
                <div className='inputContainer'>
                    <label htmlFor="endDate" style={{ marginRight: '10px' }}>Fin:</label>
                    <input
                        type="date"
                        id="endDate"
                        value={endDate}
                        onChange={handleEndDateChange}
                        style={{
                            padding: '5px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            fontFamily: 'Arial, sans-serif',
                            color: '#333',
                            fontSize: '14px',
                        }}
                    />
                </div>
            </div>
            <h3>Un total de {ticketCount} ticket(s)</h3>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart data={graphData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Tickets Cerradas" stroke="#8884d8" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default TicketsCerradas;
