import React, { useState, useEffect } from 'react';
import useAllTables from '../../hooks/useTables';
import './styles.css';
import {
    BarChart,
    CartesianGrid,
    Bar,
    XAxis,
    YAxis,
    Label,
    ResponsiveContainer,
    Tooltip,
    Legend
} from "recharts";
import Grid from '@material-ui/core/Grid';


const Statistics = () => {
    const { tickets, messages, users, loading } = useAllTables();
    const [userStats, setUserStats] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');


    useEffect(() => {
        if (loading) return;

        // Convert startDate and endDate strings to Date objects
        const start = startDate ? new Date(startDate) : new Date();
        const end = endDate ? new Date(endDate) : new Date();

        // Filter tickets based on status and date range
        const filteredTickets = tickets.filter(ticket => {
            let ticketCreatedAt = new Date(new Date(ticket.createdAt).toLocaleDateString('en-US', { timeZone: 'America/Managua' }));
            return ticket.status == 'closed' && ticketCreatedAt >= start && ticketCreatedAt <= end;
        });

        // Initialize statistics object
        const initialStats = users.map(user => ({
            id: user.id,
            name: user.name,
            ticketCount: 0,
            messageCount: 0,
        }));

        // Calculate ticketCount and messageCount for each user
        filteredTickets.forEach(ticket => {
            const userIndex = initialStats.findIndex(user => user.id === ticket.userId);
            if (userIndex !== -1) {
                initialStats[userIndex].ticketCount += 1;
                initialStats[userIndex].messageCount += messages.filter(
                    message => message.ticketId == ticket.id && message.fromMe == 1
                ).length;
            }
        });

        setUserStats(initialStats);
    }, [tickets, messages, users, loading, startDate, endDate]);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    if (loading) return <div>Loading...</div>;
    const data = userStats.map(user => ({
        name: user.name,
        'Tickets cerradas': user.ticketCount,
        'Mensajes enviados': user.messageCount,
    }));
    console.log(data);
    return (
        <div style={{ padding: '30px' }}>

            <h2 style={{ color: '#2576d2' }} className="MuiTypography-root MuiTypography-h6 MuiTypography-colorPrimary MuiTypography-gutterBottom">Estadísticas de Usuarios</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <div className='inputContainer'>
                    <label htmlFor="startDate" style={{ marginRight: '10px' }}>Inicio:</label>
                    <input
                        type="date"
                        id="startDate"
                        value={startDate}
                        onChange={handleStartDateChange}
                        style={{
                            padding: '5px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            fontFamily: 'Arial, sans-serif',
                            color: '#333',
                            fontSize: '14px',
                        }}
                    />
                </div>
                <div className='inputContainer'>
                    <label htmlFor="endDate" style={{ marginRight: '10px' }}>Fin:</label>
                    <input
                        type="date"
                        id="endDate"
                        value={endDate}
                        onChange={handleEndDateChange}
                        style={{
                            padding: '5px',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            fontFamily: 'Arial, sans-serif',
                            color: '#333',
                            fontSize: '14px',
                        }}
                    />
                </div>
            </div>
            <BarChart width={800} height={400} data={data}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Tickets cerradas" fill="#8884d8" />
                <Bar dataKey="Mensajes enviados" fill="#82ca9d" />
            </BarChart>
        </div>
    );
};

export default Statistics;
