import React, { useState, useEffect } from 'react';
import { TextField, Chip, FormControlLabel, Switch, Box, List, ListItem, ListItemText, IconButton, Paper, InputAdornment } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import api from '../../services/api'; // Import the API service

const ContactSelector = ({ selectedContacts, setSelectedContacts, isSwitchOn, onSwitchChange }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadContacts = async () => {
            try {
                const { data } = await api.get('/contacts');
                setContacts(data.contacts);
            } catch (error) {
                console.error('Error fetching contacts:', error);
            } finally {
                setLoading(false);
            }
        };
        loadContacts();
    }, []);

    const handleSwitchChange = (event) => {
        onSwitchChange(event.target.checked);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectContact = (contact) => {
        if (!selectedContacts.find(c => c.number === contact.number)) {
            setSelectedContacts(prevContacts => [...prevContacts, contact]);
        }
    };

    const handleRemoveContact = (number) => {
        setSelectedContacts(prevContacts => prevContacts.filter(c => c.number !== number));
    };

    // Filter contacts based on the search term
    const filteredContacts = searchTerm
        ? contacts.filter(contact =>
            contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            contact.number.includes(searchTerm)
        )
        : [];

    return (
        <div>
            <FormControlLabel
                control={<Switch checked={isSwitchOn} onChange={handleSwitchChange} />}
                label="Todos los contactos"
            />

            {!isSwitchOn && (
                <div>
                    <Paper style={{ padding: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', marginBottom: '16px' }}>
                        <TextField
                            variant="outlined"
                            placeholder="Buscar contactos"
                            fullWidth
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: 'gray' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Paper>

                    {searchTerm && (
                        <Box mt={2}>
                            <Paper style={{ maxHeight: 'calc(2 * 56px)', overflowY: 'auto', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
                                <List>
                                    {filteredContacts.map(contact => (
                                        <ListItem
                                            key={contact.number}
                                            button
                                            onClick={() => handleSelectContact(contact)}
                                        >
                                            <ListItemText
                                                primary={contact.name}
                                                secondary={contact.number} // Display phone number
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </Box>
                    )}

                    <Box mt={2} style={{ maxHeight: 'calc(2 * 56px)', overflowY: 'auto' }}>
                        {selectedContacts.map(contact => (
                            <Chip
                                key={contact.number}
                                label={contact.name}
                                onDelete={() => handleRemoveContact(contact.number)}
                                deleteIcon={<CloseIcon />}
                                style={{ margin: '4px' }}
                            />
                        ))}
                    </Box>
                </div>
            )}
        </div>
    );
};

export default ContactSelector;
